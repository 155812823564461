import { Tooltip, useMediaQuery } from '@mui/material'
import MuiAvatarGroup from '@mui/material/AvatarGroup'
import MKAvatar from 'components/MaterialKit/MKAvatar'
import CustomLineupSpot from 'models/CustomLineupSpot'
import User from 'models/User'

export interface ILineup {
    lineup: Array<User | CustomLineupSpot>
}
export const Lineup = ({ lineup }) => {
    const isMobile = useMediaQuery('(max-width:768px)')

    return (
        // <MKBox component="section">
        // 	<Container >
        <>
            <MuiAvatarGroup
                max={isMobile ? 8 : 8}
                sx={{
                    '& .MuiAvatar-root': {
                        width: '2rem',
                        height: '2rem',
                        fontSize: 15,
                    },
                }}
            >
                {lineup.map((user, index) => {
                    return (
                        <Tooltip
                            key={`event-lineup-${user.$id}-${index}`}
                            title={user.$name}
                            arrow={false}
                        >
                            <MKAvatar
                                className="event-lineup-avatar me-1"
                                src={user.$pictureUrl}
                                alt={user.$name}
                                size={'xl'}
                            />
                        </Tooltip>
                    )
                })}
            </MuiAvatarGroup>
        </>
        // 	</Container>
        // </MKBox>
    )
}
