import { Grid } from '@mui/material'
import MKButton from 'components/MaterialKit/MKButton'
import MKInput from 'components/MaterialKit/MKInput'
import { ChevronLeftIcon, ChevronRightIcon, MinusIcon, PlusIcon } from 'components/UI/CKIcons'
import { FC, useState } from 'react'
import './style.scss'
export interface INumberSelector {
    value: number
    onChange: (value: number) => void
    min?: number
    max?: number
    step?: number
    className?: string
    style?: React.CSSProperties
    variant?: 'standard' | 'compact'
}

export const CKNumberSelector: FC<INumberSelector> = ({
    variant = 'standard',
    value: defaultValue,
    onChange: onPropsChange,
    min = 0,
    max = 100,
    step = 1,
    className,
    style,
}) => {
    const [value, setValue] = useState(defaultValue)

    const onChange = (_value: number) => {
        if (_value < min) {
            _value = min
        } else if (_value > max) {
            _value = max
        }
        setValue(_value)
        onPropsChange(_value)
    }

    const standard = () => {
        return (
            <div
                className={`ck-number-selector ${className}`}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    ...style,
                }}
            >
                <MKButton
                    circular
                    variant="gradient"
                    color="info"
                    size="medium"
                    iconOnly
                    className="mx-3"
                    onClick={() => {
                        onChange(Math.max(value - step, min))
                    }}
                >
                    <ChevronLeftIcon />
                </MKButton>

                <MKInput
                    type="number"
                    value={value}
                    variant="standard"
                    style={{ width: '50px', textAlign: 'center' }}
                    color="info"
                    onChange={(e) => {
                        onChange(parseInt(e.target.value))
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

                <MKButton
                    circular
                    variant="gradient"
                    className="mx-3"
                    color="info"
                    size="medium"
                    iconOnly
                    onClick={() => {
                        onChange(Math.min(value + step, max))
                    }}
                >
                    <ChevronRightIcon />
                </MKButton>
            </div>
        )
    }

    const Vertical = () => {
        return (
            <div
                className={`ck-number-selector ${className}`}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    ...style,
                }}
            >
                <Grid container columnSpacing={3} rowSpacing={1}>
                    <Grid item xs={12} className="text-center">
                        <MKInput
                            type="number"
                            value={value}
                            className="w-80"
                            variant="standard"
                            style={{ width: '50px', textAlign: 'center' }}
                            color="info"
                            onChange={(e) => {
                                onChange(parseInt(e.target.value))
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} className="text-end">
                        <MKButton
                            variant="gradient"
                            color="info"
                            size="medium"
                            iconOnly
                            onClick={() => {
                                onChange(Math.max(value - step, min))
                            }}
                        >
                            <MinusIcon />
                        </MKButton>
                    </Grid>
                    <Grid item xs={6} className="text-start">
                        <MKButton
                            variant="gradient"
                            color="info"
                            size="medium"
                            iconOnly
                            onClick={() => {
                                onChange(Math.min(value + step, max))
                            }}
                        >
                            <PlusIcon />
                        </MKButton>
                    </Grid>
                </Grid>
            </div>
        )
    }

    return (
        <>
            <style>
                {`
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                    `}
            </style>
            {variant === 'standard' && standard()}
            {variant === 'compact' && <Vertical />}
        </>
    )
}
