import CKModel from './CKModel'
import CustomLineupSpot from './CustomLineupSpot'
import EventSpot from './EventSpot'
import Image from './Image'
import Location from './Location'
import Organisation from './Organisation'
import Social from './Social'
import Ticket from './Ticket'
import User from './User'

export default class Event extends CKModel {
    private description: string | undefined
    private start_time: Date | undefined
    private arrival_time: Date | undefined
    private location: Location | undefined
    private logo: Image | undefined
    private banner: Image | undefined
    private spots: EventSpot[] | undefined
    private lineupComplete: boolean
    private lineUp: User[] | undefined
    private tickets: Ticket[] | undefined
    private organisation: Organisation | undefined
    private organiser: Organisation | User | undefined
    private custom_lineup: CustomLineupSpot[] | undefined

    private location_id: string | undefined
    private banner_id: string | undefined
    private logo_id: string | undefined
    private organisation_id: string | undefined

    constructor(
        id: string | undefined = undefined,
        slug: string | undefined = undefined,
        name: string | undefined = undefined,
        description: string | undefined = undefined,
        start_time: Date | undefined = undefined,
        arrival_time: Date | undefined = undefined,
        location: Location | undefined = undefined,
        logo: Image | undefined = undefined,
        banner: Image | undefined = undefined,
        spots: EventSpot[] | undefined = undefined,
        lineupComplete: boolean = false,
        lineUp: User[] | undefined = undefined,
        socials: Social[] = [],
        custom_lineup: CustomLineupSpot[] | undefined = undefined
    ) {
        super(id, slug, socials)

        this.name = name
        this.description = description
        this.start_time = start_time
        this.arrival_time = arrival_time
        this.location = location
        this.logo = logo
        this.banner = banner
        this.spots = spots
        this.lineupComplete = lineupComplete
        this.lineUp = lineUp
        this.custom_lineup = custom_lineup
    }

    public static fromJSON(json: any) {
        let result = new Event()
        result = Object.assign(result, json)

        if (json.location) {
            result.$location = Location.fromJSON(json.location)
        }

        if (json.organisation) {
            result.organisation = Organisation.fromJSON(json.organisation)
        }

        if (json.logo) {
            result.$logo = Image.fromJSON(json.logo)
        }

        if (json.banner) {
            result.$banner = Image.fromJSON(json.banner)
        }

        if (json.start_time) {
            result.$start_time = new Date(json.start_time)
        }

        if (json.arrival_time) {
            result.$arrival_time = new Date(json.arrival_time)
        }

        if (json.spots) {
            result.$spots = []
            for (const spot of json.spots) {
                result.$spots.push(EventSpot.fromJSON(spot))
            }
        }

        if (json.tickets) {
            result.tickets = []
            for (const ticket of json.tickets) {
                result.tickets.push(Ticket.fromJSON(ticket))
            }
        }

        result.$lineupComplete = json.lineup_complete ? true : false
        if (json.lineup) {
            result.$lineUp = []
            for (const l of json.lineup) {
                const user = User.fromJSON(l)
                result.$lineUp.push(user)
            }
        } else {
            result.$lineUp = []
        }

        if (json.socials) {
            const socials: Social[] = []
            for (const social of json.socials) {
                socials.push(Social.fromJSON(social))
            }
            result.$socials = socials
        }

        if (json.organiser) {
            if (json.organiser.className === 'organisation') {
                result.$organiser = Organisation.fromJSON(json.organiser)
            } else {
                result.$organiser = User.fromJSON(json.organiser)
            }
        }

        if (json.custom_lineup) {
            try {
                if (Array.isArray(json.custom_lineup)) {
                    result.$custom_lineup = []
                    for (const spot of json.custom_lineup) {
                        result.$custom_lineup.push(CustomLineupSpot.fromJSON(spot))
                    }
                } else {
                    console.error('Parsed custom_lineup is not an array:', json.custom_lineup)
                    result.$custom_lineup = []
                }
            } catch (e) {
                console.error('Failed to parse custom_lineup:', e)
                result.$custom_lineup = []
            }
        }

        return result
    }

    public get $description(): string | undefined {
        return this.description
    }
    public set $description(value: string | undefined) {
        this.description = value
    }

    public get $start_time(): Date | undefined {
        return this.start_time
    }
    public set $start_time(value: Date | undefined) {
        this.start_time = value
    }

    public get $arrival_time(): Date | undefined {
        return this.arrival_time
    }
    public set $arrival_time(value: Date | undefined) {
        this.arrival_time = value
    }

    public get $location(): Location | undefined {
        return this.location
    }
    public set $location(value: Location | undefined) {
        this.location = value
        this.location_id = value.$id
    }

    public get $organisation(): Organisation | undefined {
        return this.organisation
    }
    public set $organisation(value: Organisation | undefined) {
        this.organisation = value
        this.organisation_id = value ? value.$id : undefined
    }

    public get $spots(): EventSpot[] | undefined {
        return this.spots
    }
    public set $spots(value: EventSpot[] | undefined) {
        this.spots = value
    }

    public get $lineupComplete(): boolean {
        return this.lineupComplete
    }
    public set $lineupComplete(value: boolean) {
        this.lineupComplete = value
    }

    public get $lineUp(): User[] | undefined {
        return this.lineUp
    }
    public set $lineUp(value: User[] | undefined) {
        this.lineUp = value
    }

    public get $logo(): Image | undefined {
        if (this.logo) return this.logo
        if (this.$location) return this.$location.$logo
        return undefined
    }
    public set $logo(value: Image | undefined) {
        this.logo = value
        this.logo_id = value.$id
    }

    public get $banner(): Image | undefined {
        if (this.banner) return this.banner
        if (this.$location) return this.$location.$banner
        return undefined
    }
    public set $banner(value: Image | undefined) {
        this.banner = value
        this.banner_id = value.$id
    }

    public get $className(): string {
        return 'event'
    }

    public get $tickets(): Ticket[] | undefined {
        return this.tickets
    }
    public set $tickets(value: Ticket[] | undefined) {
        this.tickets = value
    }

    public get $organiser(): Organisation | User | undefined {
        return this.organiser
    }
    public set $organiser(value: Organisation | User | undefined) {
        this.organiser = value
    }

    public get $custom_lineup(): CustomLineupSpot[] | undefined {
        return this.custom_lineup
    }
    public set $custom_lineup(value: CustomLineupSpot[] | undefined) {
        this.custom_lineup = value
    }

    public get $completeCombinedLineup(): Array<CustomLineupSpot | User> {
        // combine this.$lineUp and this.$custom_lineup
        if (!this.$lineUp) return this.$custom_lineup || []
        if (!this.$custom_lineup) return this.$lineUp
    
        return [...this.$lineUp, ...this.$custom_lineup]
    }

    public showLineup(): boolean {
        return this.$lineupComplete || this.$custom_lineup?.length > 0
    }


    public amountOpenSpots() {
        if (!this.$spots) return 0

        try {
            return (
                this.$spots
                    .map((spot) => spot.$amount)
                    .reduce((acc, amount) => {
                        return acc + amount
                    }) - this.$lineUp.length
            )
        } catch (e) {
            return 0
        }
    }
}
