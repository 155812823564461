import { Chip, List, ListItem, ListItemButton, ListItemIcon, Tooltip } from '@mui/material'
import { ArrowLeftIcon } from '@mui/x-date-pickers'
import MKAvatar from 'components/MaterialKit/MKAvatar'
import MKBox from 'components/MaterialKit/MKBox'
import MKButton from 'components/MaterialKit/MKButton'
import MKTypography from 'components/MaterialKit/MKTypography'
import { DeleteIcon, ErrorIcon, PlusIcon } from 'components/UI/CKIcons'
import CustomLineupSpot from 'models/CustomLineupSpot'
import { FC, useMemo, useState } from 'react'
import CustomLineupSpotEditCard from './CustomLineupSpotEditCard'

export interface ICustomLineup {
    lineup: CustomLineupSpot[] | undefined
    onLineupChange?: (lineup: CustomLineupSpot[]) => void
    errors?: any[]
}

export const CustomLineup: FC<ICustomLineup> = ({
    lineup: defaultLineup,
    onLineupChange,
    errors,
}) => {
    const [lineup, setLineup] = useState<CustomLineupSpot[]>(defaultLineup || [])
    const [openedSpot, setOpenedSpot] = useState<CustomLineupSpot | null>(null)

    useMemo(() => {
        if (onLineupChange) {
            onLineupChange(lineup)
        }
    }, [lineup, onLineupChange])

    const addNewSpot = () => {
        const newSpot = new CustomLineupSpot()
        setLineup([...lineup, newSpot])
        setOpenedSpot(newSpot)
    }

    const removeSpot = (spot: CustomLineupSpot) => {
        setLineup((prevLineup) => prevLineup.filter((value) => value !== spot))
        setOpenedSpot(null)
    }

    const openCustomLineupSpot = () => {
        if (!openedSpot) return <></>

        return (
            <>
                <MKButton
                    color="info"
                    className="mb-4"
                    onClick={() => {
                        setOpenedSpot(null)
                    }}
                >
                    <ArrowLeftIcon />
                    &nbsp; Terug naar lineup
                </MKButton>
                <CustomLineupSpotEditCard
                    customLineupSpot={openedSpot}
                    errors={errors ? errors[lineup.indexOf(openedSpot)] : null}
                />
            </>
        )
    }

    const spotItem = (spot: CustomLineupSpot, hasError: boolean) => {
        const Error = () => {
            if (!hasError) return <></>

            return (
                <Tooltip title="Er zijn fouten in deze spot" className="mx-1">
                    <ErrorIcon color="error" />
                </Tooltip>
            )
        }

        const Name = () => {
            return (
                <MKTypography variant="body" fontWeight="bold">
                    {spot.$name}
                </MKTypography>
            )
        }

        const Icon = () => {
            const url = spot.$pictureData ? spot.$pictureData.content.toString() : spot.$pictureUrl

            return <MKAvatar src={url} size="sm" shadow="sm" sx={{ bgColor: '#fffffcc' }} />
        }

        const MC = () => {
            if (!spot.$isMc) return <></>

            return <Chip label="MC" color="info" variant="outlined" className="mx-2" />
        }

        const DeleteButton = () => {
            return (
                <MKButton
                    color="error"
                    onClick={(e) => {
                        e.stopPropagation()
                        removeSpot(spot)
                    }}
                >
                    <DeleteIcon />
                </MKButton>
            )
        }

        return (
            <ListItem key={`custom-spot-${spot.$id}`} secondaryAction={<DeleteButton />} divider>
                <ListItemButton
                    onClick={() => {
                        setOpenedSpot(spot)
                    }}
                >
                    <ListItemIcon className="d-flex align-items-center">
                        <Icon />
                        <MC />
                        <Error />
                    </ListItemIcon>
                    <Name />
                </ListItemButton>
            </ListItem>
        )
    }

    return (
        <MKBox className="text-center">
            {openedSpot ? (
                openCustomLineupSpot()
            ) : (
                <>
                    <MKTypography variant="body2" className="mb-2">
                        Voeg spots toe aan de lineup waarvoor er geen bestaand account is. Deze
                        zullen worden toegevoegd aan de complete lineup van het evenement.
                    </MKTypography>
                    <MKButton variant="gradient" color="info" onClick={addNewSpot}>
                        <PlusIcon /> Nieuw
                    </MKButton>
                    <MKBox mt={4}>
                        <List>
                            {Array.isArray(lineup) &&
                                lineup.map((spot) => {
                                    return spotItem(spot, errors && errors[spot.$id])
                                })}
                        </List>
                    </MKBox>
                </>
            )}
        </MKBox>
    )
}
