import { FormControlLabel, Grid, Switch } from '@mui/material'
import CKFormFeedback from 'components/Form/CKFormFeedback'
import CKImageUpload, { IImageFile } from 'components/Form/CKImageUpload'
import MKInput from 'components/MaterialKit/MKInput'
import MKTypography from 'components/MaterialKit/MKTypography'
import CustomLineupSpot from 'models/CustomLineupSpot'
import { FC, useState } from 'react'

interface Props {
    customLineupSpot: CustomLineupSpot
    errors?: object | null
}

const CustomLineupSpotEditCard: FC<Props> = ({ customLineupSpot: defaultSpot, errors }) => {
    const [spot, setSpot] = useState<CustomLineupSpot>(defaultSpot)

    const updateSpot = (updates: Partial<CustomLineupSpot>) => {
        const _customLineupSpot = Object.create(Object.getPrototypeOf(spot), {
            ...Object.getOwnPropertyDescriptors(spot),
            ...Object.getOwnPropertyDescriptors(updates),
        })
        setSpot(_customLineupSpot)
    }

    const name = () => {
        return (
            <>
                <Grid item xs={12}>
                    <MKInput
                        label="Voornaam"
                        title="Voornaam"
                        error={errors?.['first_name'] ? true : false}
                        defaultValue={defaultSpot.$first_name}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                            defaultSpot.$first_name = e.target.value
                        }}
                        required
                        fullWidth
                    />
                    <CKFormFeedback>{errors?.['fi$first_name']}</CKFormFeedback>
                </Grid>
                <Grid item xs={12}>
                    <MKInput
                        label="Achternaam"
                        title="Achternaam"
                        error={errors?.['last_name'] ? true : false}
                        defaultValue={defaultSpot.$last_name}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                            defaultSpot.$last_name = e.target.value
                        }}
                        required
                        fullWidth
                    />
                    <CKFormFeedback>{errors?.['last_name']}</CKFormFeedback>
                </Grid>
            </>
        )
    }

    const mc = () => {
        return (
            <Grid item xs={12} md={6}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={spot.$isMc}
                            onChange={(e) => {
                                updateSpot({ $isMc: e.target.checked })
                                defaultSpot.$isMc = e.target.checked
                            }}
                            inputProps={{ 'aria-label': 'MC Switch' }}
                            size="medium"
                        />
                    }
                    label="MC"
                />
            </Grid>
        )
    }

    const picture = () => {
        return (
            <Grid item xs={12} md={6} className="text-start">
                <MKTypography variant="caption">Foto</MKTypography>
                <CKImageUpload
                    defaultImage={defaultSpot.$picture}
                    defaultImageData={defaultSpot.$pictureData}
                    onChange={(files: Array<IImageFile>) => {
                        if (files.length === 1) {
                            updateSpot({ $pictureData: files[0] })
                            defaultSpot.$pictureData = files[0]
                        } else {
                            updateSpot({ $picture: undefined, $pictureData: undefined })
                            defaultSpot.$picture = undefined
                            defaultSpot.$pictureData = undefined
                        }
                    }}
                />
            </Grid>
        )
    }

    return (
        <Grid container spacing={3} pt={1} pr={1}>
            {name()}
            {mc()}
            {picture()}
        </Grid>
    )
}
export default CustomLineupSpotEditCard
