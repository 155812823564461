import { useMediaQuery } from '@uidotdev/usehooks'
import MKButton from 'components/MaterialKit/MKButton'
import { PDFIcon, ShoppingCartIcon, TicketIcon } from 'components/UI/CKIcons'
import { useCKSearchParams } from 'context/SearchParamsContext'
import { useUserContext } from 'context/UserContext'
import Event from 'models/Event'
import Ticket from 'models/Ticket'
import { FC, useState } from 'react'
import { useMyEventTickets } from 'services/TicketService'
import { BoughtTicketsModal } from './BoughtTicketsModal'
import MKTypography from 'components/MaterialKit/MKTypography'
import MKBox from 'components/MaterialKit/MKBox'
import theme from 'assets/theme'
import { Card, Grid } from '@mui/material'
import { CKNumberSelector } from 'components/Generic/CKNumberSelector'
import AuthService from 'services/AuthService'
import ClipLoader from 'react-spinners/ClipLoader'
import { isValidEmail } from 'utils/EmailUtils'
import MKInput from 'components/MaterialKit/MKInput'
import { buyTickets as buyTicketsApiCall } from 'services/TicketService'

export interface ITicketsComponent {
    tickets: Ticket[]
    event: Event;
    className?: string,
    [key: string]: any
}

export const TicketsComponent: FC<ITicketsComponent> = ({ tickets, event, className, ...props }) => {
    const { transaction } = useCKSearchParams()
    const { user } = useUserContext()
    const [basket, setBasket] = useState<{ [key: string]: number }>({})
    const amountInBasket = Object.values(basket).reduce((acc, curr) => acc + curr, 0)
    const [email, setEmail] = useState<string>()


    const MyTickets = () => {
        const { tickets: myTickets } = useMyEventTickets({ event })
        const isMobile = useMediaQuery('(max-width:768px)')
        const [isDownloading, setIsDownloading] = useState(false)

        const downloadTickets = () => {
            setIsDownloading(true)
            const eventSlug = event.$slug
            const url = `${process.env.REACT_APP_API_URL}events/${eventSlug}/tickets/pdf`

            const anchor = document.createElement('a')
            document.body.appendChild(anchor)

            const headers = new Headers()
            headers.append('Authorization', `Bearer ${AuthService._getJWT()}`)

            fetch(url, { headers })
                .then((response) => response.blob())
                .then((blobby) => {
                    const objectUrl = window.URL.createObjectURL(blobby)

                    anchor.href = objectUrl
                    anchor.download = `tickets-${event.$slug}.pdf`
                    anchor.click()

                    window.URL.revokeObjectURL(objectUrl)
                    setIsDownloading(false)
                })
        }

        return (
            <>
                {myTickets.length > 0 && (
                    <>
                        <MKButton
                            variant="gradient"
                            color="info"
                            className={`ms-3 px-6 py-3 ${isMobile ? 'mt-2' : 'ms-3'}`}
                            onClick={(e) => {
                                e.preventDefault()
                                downloadTickets()
                            }}
                        >
                            {isDownloading ? (
                                <ClipLoader size={15} color="white" className="me-2" />
                            ) : (
                                <PDFIcon className="me-2" />
                            )} Download je tickets
                        </MKButton>
                    </>
                )}
            </>
        )
    }

    const renderTicketRow = (ticket: Ticket) => (
        <Card
            key={ticket.$id}
            className="mb-3 p-3"
            style={{
                backgroundColor: theme['palette'].info.main + '10'
            }}>
            <Grid container>
                <Grid item xs={8} md={10}>
                    <Grid item xs={12} className='d-flex flex-col justify-content-between align-items-center'>
                        <div className='d-flex flex-col'>

                            <MKTypography color="dark" fontWeight="bold" variant="h6" className="me-4">
                                {ticket.$title}
                            </MKTypography>
                            <MKTypography color="text" variant="h6" fontWeight="bold">
                                €{ticket.$price}
                            </MKTypography>
                        </div>
                        {ticket.$myBoughtAmount > 0 && (
                            <MKTypography
                                color='info'
                                variant="button"
                                fontWeight="bold"
                                className="ms-2"
                            >
                                {ticket.$myBoughtAmount} in bezit
                            </MKTypography>
                        )}
                    </Grid>
                    <Grid item xs={12} className='mt-2'>
                        <MKTypography
                            variant="body2"
                            className="mb-1"
                            color="text"
                            style={{ fontSize: '0.875rem' }}
                        >
                            {ticket.$description}
                        </MKTypography>
                    </Grid>
                </Grid>
                <Grid item xs={4} md={2} className="d-flex align-items-center justify-content-center">
                    <CKNumberSelector
                        value={basket[ticket.$id] ?? 0}
                        onChange={(value) => {
                            setBasket((prev) => {
                                return {
                                    ...prev,
                                    [ticket.$id]: value,
                                }
                            })
                        }}
                        variant="compact"
                    />
                </Grid>
            </Grid>
        </Card>
    )

    const buyTickets = async () => {
        const resp = await buyTicketsApiCall(event, basket, email)

        //open new tab with the payment link
        window.location.href = resp
    }

    if (!tickets || tickets.length === 0) return null

    return (
        <MKBox className={`text-center ${className}`} {...props}>
            <MKTypography color="info" variant="label" fontWeight="bold" className="d-flex align-items-center justify-content-center">
                <TicketIcon className="me-2" />Tickets
            </MKTypography>
            {!user && (
                <MKInput
                    variant="outlined"
                    type="email"
                    name="email"
                    className="my-3"
                    error={isValidEmail(email) === false && email !== ''}
                    label="Email"
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value)
                    }}
                    fullWidth
                />
            )}
            <MKBox className="mt-3 text-start">
                {tickets.map((ticket) => (
                    renderTicketRow(ticket)
                ))}
            </MKBox>
            <MKBox className="d-flex justify-content-end gap-3">
                {user && <MyTickets />}
                <MKButton
                    color="primary"
                    variant="gradient"
                    disabled={amountInBasket === 0}
                    className="d-flex align-items-center justify-content-center py-2 px-3"
                    onClick={buyTickets}
                >
                    <ShoppingCartIcon className="me-2" />
                    Bestel tickets
                </MKButton>
            </MKBox>
            {Boolean(transaction) && <BoughtTicketsModal event={event} transaction={transaction} />}
        </MKBox>
    )
}
