import { Capacitor } from '@capacitor/core'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import MKTypography from 'components/MaterialKit/MKTypography'
import { FC, useState } from 'react'
import { ISidebarTabs } from '.'

export const CKMobileSidebarTabs: FC<ISidebarTabs> = ({ sections, hideGroup, footer }) => {
    const [expanded, setExpanded] = useState<string | false>(false)

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false)
    }

    return (
        <>
            {Object.keys(sections).map((group) => {
                const groupMenu = sections[group]
                return (
                    <div className="mb-4" key={`settings-group-${group}`}>
                        {!hideGroup && group}
                        {Object.keys(groupMenu).map((key) => {
                            const menuItem = groupMenu[key]
                            if (
                                menuItem.hasOwnProperty('onlyMobile') &&
                                !Capacitor.isNativePlatform()
                            ) {
                                return
                            }
                            return (
                                <Accordion
                                    expanded={expanded === key}
                                    onChange={handleChange(key)}
                                    key={'accordion-' + key}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel-${key}-content`}
                                        id={`panel-${key}-header`}
                                    >
                                        <div className="d-flex align-items-center">
                                            <MKTypography
                                                sx={{
                                                    fontSize: 'h6.fontSize',
                                                    fontWeight: 'bold',
                                                }}
                                                className="setting-title text-start"
                                            >
                                                {menuItem.title}
                                            </MKTypography>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>{menuItem.component}</AccordionDetails>
                                </Accordion>
                            )
                        })}
                        {footer}
                    </div>
                )
            })}
        </>
    )
}
