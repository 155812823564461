/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import 'bootstrap/dist/css/bootstrap.min.css'

import { FC, useEffect, useState } from 'react'

// react-router components
import { useLocation } from 'react-router-dom'

// @mui material components
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'

// Material Kit 2 React themes
import { ToastContainer } from 'react-toastify'
import theme from './assets/theme'

// Material Kit 2 React routes
import { Capacitor } from '@capacitor/core'
import { IonApp, IonRouterOutlet } from '@ionic/react'
import { Storage } from '@ionic/storage'
import { useLoadScript } from '@react-google-maps/api'
import AppUrlListener from 'AppUrlListener'
import CKPushNotifications from 'components/CKPushNotifications'
import CKCoffee from 'components/UI/CKCoffee'
import CKFooter from 'components/UI/CKFooter'
import CKNavbar from 'components/UI/CKNavbar'
import CKRoutes from 'components/UI/CKRoutes'
import { DarkModeProvider } from 'context/DarkModeContext'
import { MqttProvider } from 'context/MqttContext'
import { UserProvider } from 'context/UserContext'
import UserContextComponent from 'context/UserContext/UserContextComponent'
import CKHelmet from 'HOC/CKHelmet'
import MobileTweaks from 'HOC/MobileTweaks'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'react-toastify/dist/ReactToastify.css'
import { ckFetcher } from 'services/AuthService'
import { SWRConfig } from 'swr'

const libraries = ['places']

const App: FC = () => {
    const [defaultDark, setDefaultDark] = useState<boolean>()
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        // @ts-ignore
        libraries,
    })

    const initDarkMode = async () => {
        try {
            const store = new Storage()
            await store.create()
            const result = await store.get('ck-dark-mode')
            setDefaultDark(result === 'true')
        } catch (e) {
            setDefaultDark(false)
        }
    }

    const { pathname } = useLocation()

    // Setting page scroll to 0 when changing the route
    useEffect(() => {
        if (document.documentElement) document.documentElement.scrollTop = 0
        if (document.scrollingElement) document.scrollingElement.scrollTop = 0

        initDarkMode()
    }, [pathname])

    const [disableOverflow, setDisableOverflow] = useState(false)

    return (
        <>
            {defaultDark !== undefined && isLoaded && (
                <IonApp>
                    <MqttProvider>
                        <SWRConfig
                            value={{
                                revalidateOnFocus: false,
                                fetcher: ckFetcher,
                            }}
                        >
                            {' '}
                            <DarkModeProvider defaultDark={defaultDark}>
                                <UserProvider>
                                    <ThemeProvider theme={theme}>
                                        <UserContextComponent />
                                        <CssBaseline />
                                        <CKNavbar />
                                        <AppUrlListener />

                                        {Capacitor.isNativePlatform() &&
                                            Capacitor.isPluginAvailable('PushNotifications') && (
                                                <CKPushNotifications />
                                            )}

                                        <IonRouterOutlet
                                            style={{
                                                position: disableOverflow ? 'absolute' : 'relative',
                                                overflowX: 'hidden',
                                                overflowY: disableOverflow ? 'hidden' : 'auto',
                                            }}
                                            // onPointerEnterCapture={() => { }}
                                            // onPointerLeaveCapture={() => { }}
                                        >
                                            <CKHelmet />
                                            <MobileTweaks />
                                            <CKRoutes
                                                setDisableOverflow={(value) => {
                                                    if (value !== disableOverflow)
                                                        setDisableOverflow(value)
                                                }}
                                            />
                                            {/* {getRoutes(routes)} */}
                                            {/* <Route exact path="/" component={Home} /> */}
                                        </IonRouterOutlet>
                                        <ToastContainer containerId={'B'} position="top-right" />
                                        <CKFooter />

                                        <CKCoffee />
                                    </ThemeProvider>
                                </UserProvider>
                            </DarkModeProvider>
                        </SWRConfig>
                    </MqttProvider>
                </IonApp>
            )}
        </>
    )
}
export default App
