import { Container, Grid } from '@mui/material'
import CKFormFeedback from 'components/Form/CKFormFeedback'
import CKLocationPicker from 'components/Form/CKLocationPicker'
import MKInput from 'components/MaterialKit/MKInput'
import { CKPageTitle } from 'components/UI/CKPageContent'
import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { useEventEdit } from 'services/EventService'

import CKImageUpload from 'components/Form/CKImageUpload'
import { CKOrganisationSelect } from 'components/Form/CKOrganisationSelect'
import CKSocialsForm from 'components/Form/CKSocialsForm'
import MKAlert from 'components/MaterialKit/MKAlert'
import MKButton from 'components/MaterialKit/MKButton'
import MKTypography from 'components/MaterialKit/MKTypography'
import {
    CustomLineupIcon,
    ImageIcon,
    InfoIcon,
    LocationIcon,
    MicIcon,
    SaveIcon,
    ShareIcon,
    TicketIcon,
} from 'components/UI/CKIcons'
import { CKSidebarTabs } from 'components/UI/CKSidebarTabs'
import ClipLoader from 'react-spinners/ClipLoader'
import { useUserLocations } from 'services/LocationService'
import { EventPageContainer } from '../EventPageContainer'
import { CustomLineup } from './CustomLineup'
import { EventTimes } from './EventTimes'
import SpotConfig from './SpotConfig'
import { TicketConfig } from './TicketConfig'

interface Props {}

const PageEventEdit: FC<Props> = () => {
    const eventId = useParams()['eventId']

    // const [loading, setLoading] = useState<boolean>(true)

    const { hasLocations, isLoading: isLoadingLocations } = useUserLocations()

    const { event, loading, setLogoData, setBannerData, saving, save, errors } =
        useEventEdit(eventId)

    const renderGeneral = () => {
        if (!event) return null
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MKInput
                        label="Naam"
                        error={errors['name'] ? true : false}
                        title="Naam"
                        defaultValue={event.$name}
                        InputLabelProps={{ shrink: 'true' }}
                        onChange={(e) => {
                            event.$name = e.target.value
                        }}
                        required
                        fullWidth
                    />
                    <CKFormFeedback>{errors['name']}</CKFormFeedback>
                </Grid>
                <Grid item xs={12}>
                    <MKInput
                        multiline
                        defaultValue={event.$description ?? ''}
                        placeholder="Geef een beschrijving van het evenement..."
                        title="Beschrijving"
                        label="Beschrijving"
                        variant="outlined"
                        onChange={(e) => {
                            event.$description = e.target.value
                        }}
                        InputLabelProps={{ shrink: 'true' }}
                        rows={8}
                        fullWidth
                    />
                </Grid>
                <EventTimes
                    start={event.$start_time}
                    arrival={event.$arrival_time}
                    errors={errors}
                    onChange={(start, arrival) => {
                        event.$start_time = start
                        event.$arrival_time = arrival
                    }}
                />
                <Grid item xs={12} md={12}>
                    <CKOrganisationSelect
                        defaultOrganisation={event.$organisation}
                        onChange={(org) => {
                            event.$organisation = org
                        }}
                    />
                </Grid>
            </Grid>
        )
    }

    const renderImageForm = () => {
        if (!event) return null
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <MKTypography variant="caption">Logo</MKTypography>
                    <CKImageUpload
                        defaultImage={event.$logo}
                        onChange={(
                            files: Array<{
                                fileName: string
                                ext: string
                                content: string | ArrayBuffer
                            }>
                        ) => {
                            setLogoData(files[0])
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={8}>
                    <MKTypography variant="caption">Banner</MKTypography>
                    <CKImageUpload
                        defaultImage={event.$banner}
                        onChange={(
                            files: Array<{
                                fileName: string
                                ext: string
                                content: string | ArrayBuffer
                            }>
                        ) => {
                            setBannerData(files[0])
                        }}
                    />
                </Grid>
            </Grid>
        )
    }

    const sidebarTabs = {
        root: {
            General: {
                title: 'Algemeen',
                icon: <InfoIcon />,
                component: renderGeneral(),
            },
            Location: {
                title: 'Locatie',
                icon: <LocationIcon />,
                component: (
                    <CKLocationPicker
                        defaultValue={event?.$location}
                        error={errors['location']}
                        onSelect={(e) => {
                            event.$location = e
                        }}
                        required
                    />
                ),
            },
            Images: {
                title: 'Afbeeldingen',
                icon: <ImageIcon />,
                component: renderImageForm(),
            },
            Socials: {
                title: 'Socials',
                icon: <ShareIcon />,
                component: <CKSocialsForm model={event} />,
            },
            SpotConfig: {
                title: 'Spot Configuratie',
                icon: <MicIcon />,
                component: (
                    <SpotConfig
                        event={event}
                        onUpdate={(spots) => {
                            event.$spots = spots
                        }}
                    />
                ),
            },
            CustomLineup: {
                title: 'Custom line-up',
                icon: <CustomLineupIcon />,
                component: (
                    <CustomLineup
                        lineup={event?.$custom_lineup}
                        onLineupChange={(spots) => {event.$custom_lineup = spots}}
                    />
                ),
            },
            Tickets: {
                title: 'Tickets',
                icon: <TicketIcon />,
                component: (
                    <TicketConfig
                        event={event}
                        onUpdate={(tickets) => {
                            event.$tickets = tickets
                        }}
                    />
                ),
            },
        },
    }

    return (
        <EventPageContainer event={event} loading={loading}>
            <CKPageTitle>
                {eventId === 'new' ? 'Creëer een evenement' : 'Pas evenement aan'}
            </CKPageTitle>

            {/* {event && !isLoadingLocations && <CKWizard steps={wizardSteps} />} */}
            <Container className="my-3">
                {!isLoadingLocations && !hasLocations && (
                    <MKAlert dismissible color="error">
                        Je hebt nog geen locaties om evenementen te organiseren.{' '}
                        <a
                            className="mx-1"
                            style={{
                                color: 'white',
                                textDecoration: 'underline',
                            }}
                            href="/admin/location/new"
                        >
                            <u>Klik hier</u>
                        </a>{' '}
                        om een locatie aan te maken!
                    </MKAlert>
                )}
                {event && !isLoadingLocations && (
                    <CKSidebarTabs
                        sections={sidebarTabs}
                        footer={
                            <div className="text-center">
                                <MKButton
                                    type="submit"
                                    variant="gradient"
                                    color="info"
                                    onClick={save}
                                    disabled={saving}
                                >
                                    {saving ? (
                                        <p className="mb-0 me-2">
                                            <ClipLoader size={15} color={'white'} />
                                        </p>
                                    ) : (
                                        <SaveIcon className="me-2" />
                                    )}
                                    {eventId === 'new' ? 'Creëer evenement!' : 'Sla op!'}
                                </MKButton>
                            </div>
                        }
                        hideGroup
                    />
                )}
            </Container>
            {/* {event && !isLoadingLocations && renderForm()} */}
        </EventPageContainer>
    )
}
export default PageEventEdit
