import { v4 as uuid } from 'uuid'
import Image from './Image'
import { IImageFile } from 'components/Form/CKImageUpload'
import { DEFAULT_PROFILE_PICTURE } from 'utils/Constants'

interface CustomLineupSpotProps {
    id?: string
    first_name?: string
    last_name?: string
    isMc?: boolean
    picture?: Image
    pictureData?: IImageFile
}

export default class CustomLineupSpot {
    private id: string
    private first_name: string | undefined
    private last_name: string | undefined
    private picture: Image | undefined
    private pictureData: IImageFile | undefined
    private isMc: boolean

    private picture_id: string | undefined

    constructor({
        id = uuid(),
        first_name,
        last_name,
        isMc = false,
        picture,
        pictureData,
    }: CustomLineupSpotProps = {}) {
        this.id = id
        this.first_name = first_name
        this.last_name = last_name
        this.isMc = isMc
        this.picture = picture
        this.pictureData = pictureData
    }

    public static fromJSON(json: any) {
        const result = new CustomLineupSpot({
            id: json.id,
            first_name: json.first_name,
            last_name: json.last_name,
            isMc: typeof json.isMc === 'boolean' ? json.isMc : false,
            picture: json.picture ? Image.fromJSON(json.picture) : undefined,
            pictureData: json.pictureData,
        })

        return result
    }

    public toJSON() {
        // eslint-disable-next-line unused-imports/no-unused-vars
        const { pictureData, ...rest } = this
        // Remove pictureData from the object
        return rest
    }

    public get $id(): string {
        return this.id
    }
    public set $id(value: string) {
        this.id = value
    }

    public get $name(): string {
        return (this.first_name ?? '') + ' ' + (this.last_name ?? '')
    }

    public get $first_name(): string | undefined {
        return this.first_name
    }
    public set $first_name(value: string | undefined) {
        this.first_name = value
    }

    public get $last_name(): string | undefined {
        return this.last_name
    }
    public set $last_name(value: string | undefined) {
        this.last_name = value
    }

    public get $isMc(): boolean {
        return this.isMc
    }
    public set $isMc(value: boolean) {
        if (typeof value === 'boolean') {
            this.isMc = value
        } else {
            throw new Error('isMc must be a boolean')
        }
    }

    public get $picture(): Image | undefined {
        return this.picture ?? undefined
    }
    public set $picture(value: Image | undefined) {
        this.picture = value
        this.picture_id = value ? value.$id : undefined
    }
    public get $pictureUrl(): string {
        if (this.picture?.$url) {
            return this.picture.$url
        }
        return DEFAULT_PROFILE_PICTURE
    }


    public get $pictureData(): IImageFile | undefined {
        return this.pictureData
    }
    public set $pictureData(value: IImageFile | undefined) {
        this.pictureData = value
    } 

    public validate(): boolean {
        const errors: any = {}

        const defaultMsg = 'Verplicht veld!'

        // Validate first_name
        errors['first_name'] = !this.$first_name ? defaultMsg : false

        // Validate last_name
        errors['last_name'] = !this.$last_name ? defaultMsg : false

        return errors
    }
}