/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { FC, useState } from 'react'

// react-router-dom components
import { Link, useHistory } from 'react-router-dom'

// @mui material components
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'

// @mui icons

// Material Kit 2 React components
import MKBox from 'components/MaterialKit/MKBox'
import MKButton from 'components/MaterialKit/MKButton'
import MKInput from 'components/MaterialKit/MKInput'
import MKTypography from 'components/MaterialKit/MKTypography'

// Images
import bgImage from 'assets/images/bg-sign-in-basic.jpeg'
import CKFormFeedback from 'components/Form/CKFormFeedback'
import { useUserContextDispatch } from 'context/UserContext'
import AuthService from 'services/AuthService'

import { Capacitor } from '@capacitor/core'
import { Toast } from '@capacitor/toast'
import { toast, Zoom } from 'react-toastify'
import PasswordUtils from 'utils/PasswordUtils'
import { useCKSearchParams } from 'context/SearchParamsContext'

interface Props { }
const LoginPage: FC<Props> = () => {
    const userContextDispatch = useUserContextDispatch()
    const history = useHistory()

    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [confirmPassword, setConfirmPassword] = useState<string>('')

    const { forgotPassword: forgotPasswordSearchParam } = useCKSearchParams()

    const [forgotPassword, setForgotPassword] = useState<boolean>(false)
    const [resetPassword, setResetPassword] = useState<boolean>(Boolean(forgotPasswordSearchParam))

    const [formFeedback, setFormFeedback] = useState<string>('')


    const login = async () => {
        try {
            const user = await AuthService.login(email, password)

            userContextDispatch({
                type: 'LOGIN',
                user: user,
            })

            window.location.href = '/'
        } catch (e) {
            if (e.response.status === 401) {
                setFormFeedback('Inloggegevens zijn niet correct')
            } else {
                console.error(e)
            }
        }
    }

    const sendForgotPassword = async () => {
        try {
            await AuthService.forgotPassword(email)
            if (Capacitor.isNativePlatform()) {
                await Toast.show({
                    text: 'Mail verzonden',
                })
            } else {
                toast.success('Mail verzonden', {
                    containerId: 'B',
                    transition: Zoom,
                    autoClose: 2000,
                })
            }

            setFormFeedback('')
            setPassword('')
            setForgotPassword(false)
        } catch (e) {
            console.error(e)
        }
    }

    const sendResetPassword = async () => {
        if (!PasswordUtils.isStrongPassword(password)) {
            setFormFeedback('Wachtwoord niet sterk genoeg')
        }
        if (password !== confirmPassword) {
            setFormFeedback('Wachtwoorden komen niet overeen')
            return
        }

        try {
            await AuthService.resetPassword(forgotPasswordSearchParam, password)

            if (Capacitor.isNativePlatform()) {
                await Toast.show({
                    text: 'Wachtwoord veranderd',
                })
            } else {
                toast.success('Wachtwoord veranderd', {
                    containerId: 'B',
                    transition: Zoom,
                    autoClose: 2000,
                })
            }

            history.push('/login')

            setFormFeedback('')
            setPassword('')
            setConfirmPassword('')
            setResetPassword(false)
        } catch (err) {
            if (err.response.status === 400) {
                setFormFeedback('Link niet meer geldig')
            } else {
                console.error(err)
            }
        }
    }

    /**
     * Event handler on chat input
     * @param e
     */
    const handleTextInput = async (e) => {
        if (e.key === 'Enter') {
            login()
        }
    }

    const renderResetPassword = () => {
        return (
            <>
                <MKBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    mx={2}
                    mt={-3}
                    p={2}
                    mb={1}
                    textAlign="center"
                >
                    <MKTypography variant="h4" fontWeight="medium" color="white" my={1}>
                        Wachtwoord veranderen
                    </MKTypography>
                </MKBox>
                <MKBox pt={4} pb={3} px={3}>
                    <MKBox component="form" role="form">
                        <MKBox>
                            <MKInput
                                type="password"
                                name="password"
                                label="Wachtwoord"
                                value={password}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        sendResetPassword()
                                    }
                                }}
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                    setFormFeedback('')
                                }}
                                fullWidth
                            />
                            <MKTypography variant="caption" sx={{ display: 'block' }}>
                                8+ karakters met minstens 1 kleine letter, 1 hoofdletter en 1 cijfer
                            </MKTypography>
                        </MKBox>
                        <MKBox mt={2}>
                            <MKInput
                                type="password"
                                name="confirmPassword"
                                label="Bevestig wachtwoord"
                                value={confirmPassword}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        sendResetPassword()
                                    }
                                }}
                                onChange={(e) => {
                                    setConfirmPassword(e.target.value)
                                    setFormFeedback('')
                                }}
                                fullWidth
                            />
                        </MKBox>
                        <CKFormFeedback>{formFeedback}</CKFormFeedback>
                        <MKBox mt={4}>
                            <MKButton
                                variant="gradient"
                                color="info"
                                onClick={sendResetPassword}
                                fullWidth
                            >
                                Veranderen
                            </MKButton>
                        </MKBox>
                        <MKBox mt={3} textAlign="center">
                            <MKTypography
                                variant="button"
                                color="info"
                                fontWeight="medium"
                                textGradient
                                onClick={() => {
                                    setForgotPassword(false)
                                    setFormFeedback('')
                                }}
                                sx={{ cursor: 'pointer' }}
                            >
                                Ga naar login
                            </MKTypography>
                        </MKBox>
                    </MKBox>
                </MKBox>
            </>
        )
    }

    const renderForgotPassword = () => {
        return (
            <>
                <MKBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    mx={2}
                    mt={-3}
                    p={2}
                    mb={1}
                    textAlign="center"
                >
                    <MKTypography variant="h4" fontWeight="medium" color="white" my={1}>
                        Wachtwoord vergeten
                    </MKTypography>
                </MKBox>
                <MKBox pt={4} pb={3} px={3}>
                    <MKBox component="form" role="form">
                        <MKBox mb={2}>
                            <MKInput
                                type="email"
                                name="email"
                                label="Email"
                                value={email}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        sendForgotPassword()
                                    }
                                }}
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                }}
                                fullWidth
                            />
                        </MKBox>
                        <MKBox mt={4}>
                            <MKButton
                                variant="gradient"
                                color="info"
                                onClick={sendForgotPassword}
                                fullWidth
                            >
                                Verzenden
                            </MKButton>
                        </MKBox>
                        <MKBox mt={3} textAlign="center">
                            <MKTypography
                                variant="button"
                                color="info"
                                fontWeight="medium"
                                textGradient
                                onClick={() => setForgotPassword(false)}
                                sx={{ cursor: 'pointer' }}
                            >
                                Ga naar login
                            </MKTypography>
                        </MKBox>
                    </MKBox>
                </MKBox>
            </>
        )
    }

    return (
        <>
            <MKBox
                position="fixed"
                top={0}
                left={0}
                zIndex={0}
                width="100%"
                minHeight="100vh"
                sx={{
                    backgroundImage: ({
                        functions: { linearGradient, rgba },
                        palette: { gradients },
                    }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}
            />
            <MKBox
                // px={1}
                // position="absolute"
                width="100%"
                height="100vh"
                top={0}
                mx="auto"
                position="relative"
                zIndex={2}
            >
                <Grid
                    container
                    spacing={1}
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                >
                    <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
                        <Card>
                            {resetPassword ? (
                                renderResetPassword()
                            ) : forgotPassword ? (
                                renderForgotPassword()
                            ) : (
                                <>
                                    <MKBox
                                        variant="gradient"
                                        bgColor="info"
                                        borderRadius="lg"
                                        coloredShadow="info"
                                        mx={2}
                                        mt={-3}
                                        p={2}
                                        mb={1}
                                        textAlign="center"
                                    >
                                        <MKTypography
                                            variant="h4"
                                            fontWeight="medium"
                                            color="white"
                                            my={1}
                                        >
                                            Inloggen
                                        </MKTypography>
                                    </MKBox>
                                    <MKBox pt={4} pb={3} px={3}>
                                        <MKBox component="form" role="form">
                                            <MKBox mb={2}>
                                                <MKInput
                                                    type="email"
                                                    name="email"
                                                    label="Email"
                                                    value={email}
                                                    onKeyPress={handleTextInput}
                                                    onChange={(e) => {
                                                        setEmail(e.target.value)
                                                        setFormFeedback('')
                                                    }}
                                                    fullWidth
                                                />
                                            </MKBox>
                                            <MKBox>
                                                <MKInput
                                                    type="password"
                                                    name="password"
                                                    label="Wachtwoord"
                                                    value={password}
                                                    onKeyPress={handleTextInput}
                                                    onChange={(e) => {
                                                        setPassword(e.target.value)
                                                        setFormFeedback('')
                                                    }}
                                                    fullWidth
                                                />
                                            </MKBox>
                                            <CKFormFeedback>{formFeedback}</CKFormFeedback>
                                            {/* <MKBox display="flex" alignItems="center" ml={-1}>
										<Switch
											checked={rememberMe}
											onChange={handleSetRememberMe}
										/>
										<MKTypography
											variant="button"
											fontWeight="regular"
											color="text"
											onClick={handleSetRememberMe}
											sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
										>
											&nbsp;&nbsp;Remember me
										</MKTypography>
									</MKBox> */}
                                            <MKBox mt={4}>
                                                <MKButton
                                                    variant="gradient"
                                                    color="info"
                                                    onClick={login}
                                                    fullWidth
                                                >
                                                    Log in
                                                </MKButton>
                                            </MKBox>
                                            <MKBox mt={2} textAlign="center">
                                                <MKTypography
                                                    variant="button"
                                                    color="info"
                                                    fontWeight="medium"
                                                    textGradient
                                                    onClick={() => {
                                                        setForgotPassword(true)
                                                        setFormFeedback('')
                                                    }}
                                                    sx={{ cursor: 'pointer' }}
                                                >
                                                    Wachtwoord vergeten?
                                                </MKTypography>
                                            </MKBox>
                                            <MKBox mt={2} textAlign="center">
                                                <MKTypography variant="button" color="text">
                                                    Nog geen account?{' '}
                                                    <MKTypography
                                                        component={Link}
                                                        to="/register"
                                                        variant="button"
                                                        color="info"
                                                        fontWeight="medium"
                                                        textGradient
                                                    >
                                                        Registreer
                                                    </MKTypography>
                                                </MKTypography>
                                            </MKBox>
                                        </MKBox>
                                    </MKBox>
                                </>
                            )}
                        </Card>
                    </Grid>
                </Grid>
            </MKBox>
            {/* <MKBox width="100%" position="absolute" zIndex={2} bottom="1.625rem">
				<SimpleFooter light />
			</MKBox> */}
        </>
    )
}

export default LoginPage
