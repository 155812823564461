import MKButton from 'components/MaterialKit/MKButton'
import MKTypography from 'components/MaterialKit/MKTypography'
import { DeleteIcon } from 'components/UI/CKIcons'
import Image from 'models/Image'
import { FC, useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import './style.scss'
interface ICKImageUpload {
    defaultImage?: Image
    defaultImageData?: IImageFile
    onChange?: (
        files: Array<IImageFile>
    ) => void
}

export interface IImageFile {
    fileName: string
    ext: string
    content: string | ArrayBuffer
}

const readUrlData = (url: string): Promise<string | ArrayBuffer | null> => {
    if (!url) return

    return new Promise((resolve) => {
        const request = new XMLHttpRequest()
        request.open('GET', url, true)
        request.responseType = 'blob'
        request.onload = function () {
            const reader = new FileReader()
            reader.readAsDataURL(request.response)
            reader.onload = function (e) {
                resolve(e.target.result)
            }
        }
        request.send()
    })
}

const CKImageUpload: FC<ICKImageUpload> = ({ defaultImage, defaultImageData, onChange }) => {
    const [selectedFile, setSelectedFile] = useState<string | ArrayBuffer | null>(defaultImageData?.content)

    useEffect(() => {
        if (defaultImage) {
            readUrlData(defaultImage.$url).then((data) => {
                setSelectedFile(data)
            })
        }
    }, [])

    const readFile = (file) => {
        return new Promise((resolve: (value: string | ArrayBuffer) => void) => {
            const fr = new FileReader()

            fr.onload = async (e) => {
                const result: string | ArrayBuffer = e.target.result
                resolve(result)
            }
            fr.readAsDataURL(file)
        })
    }

    const onDrop = useCallback(
        async (acceptedFiles) => {
            const file = acceptedFiles[0]
            const fileName = file.name
            const ext = fileName.split('.').pop()
            const content = await readFile(file)

            setSelectedFile(content)
            if (onChange) onChange([{ fileName, ext, content }])
        },
        [onChange]
    )
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    const renderImage = () => {
        return (
            <>
                <div
                    className="overlay-container p-3 h-100 d-flex justify-content-center rounded align-items-center relative"
                    style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
                >
                    <img src={selectedFile.toString()} width={'100%'} className="rounded" />
                    <div
                        className="overlay"
                        onClick={() => {
                            setSelectedFile(null)
                            if (onChange) onChange([])
                        }}
                    >
                        <DeleteIcon fontSize="large" />
                    </div>
                </div>
            </>
        )
    }

    const renderUploadComponent = () => {
        return (
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div
                    className="d-flex justify-content-center align-items-center p-2 border-secondary rounded border-2"
                    style={{ border: '2px dashed', height: '150px' }}
                >
                    {isDragActive ? (
                        <p>Drop uwe foto hier ...</p>
                    ) : (
                        <div className="text-center">
                            <MKButton variant="gradient" color="info" className="mb-4">
                                Selecteer een foto
                            </MKButton>
                            <MKTypography variant="body2">Of drop 'm gewoon hier</MKTypography>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    return <>{selectedFile ? renderImage() : renderUploadComponent()}</>
}
export default CKImageUpload
