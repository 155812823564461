import { Card, Grid } from '@mui/material'
import MKButton from 'components/MaterialKit/MKButton'
import MKTypography from 'components/MaterialKit/MKTypography'
import EventSpot from 'models/EventSpot'
import { FC, useState } from 'react'
import './style.scss'

import { useTheme } from '@emotion/react'
import useForceUpdate from 'HOC/forceUpdate'
import { CKNumberSelector } from 'components/Generic/CKNumberSelector'
import { DeleteIcon, ExpandLessIcon, ExpandMoreIcon } from '../CKIcons'
interface Props {
    spot: EventSpot
    onDelete?: (spot: EventSpot) => void
}
const CKSpotEditCard: FC<Props> = (props) => {
    const forceUpdate = useForceUpdate()
    const theme = useTheme()
    const [open, setOpen] = useState(false)

    const renderHeader = () => {
        return (
            <>
                <div className="event-spot-card-header" >
                    {props.spot.$type === 'MC' ? (
                        <MKTypography variant="caption" fontWeight="bold" className="spot-title">
                            <span
                                style={{
                                    color: theme['palette'].info.main,
                                }}
                            >
                                Open spot voor MC
                            </span>
                        </MKTypography>
                    ) : (
                        <MKTypography variant="caption" fontWeight="bold" className="spot-title">
                            <span
                                style={{
                                    color: theme['palette'].info.main,
                                }}
                            >
                                {props.spot.$amount}
                            </span>{' '}
                            spots van{' '}
                            <span
                                style={{
                                    color: theme['palette'].info.main,
                                }}
                            >
                                {props.spot.$length}
                            </span>{' '}
                            min.
                        </MKTypography>
                    )}
                </div>
                <MKButton
                    circular
                    size="medium"
                    className="spot-collapse-btn m-1"
                    iconOnly

                >
                    {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </MKButton>
            </>
        )
    }

    return (
        <Card className="p-3 ck-spot-edit-card w-100">
            <div
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setOpen(!open)
                }}
            >
                {renderHeader()}
            </div>
            <Grid container className="py-2" spacing={3}>
                {open && (
                    <>
                        {props.spot.$type !== 'MC' && (
                            <>
                                <Grid item xs={12} md={6} className="text-center">
                                    <MKTypography
                                        variant="caption"
                                        fontWeight="bold"
                                        className="text-center w-100"
                                    >
                                        Aantal personen
                                    </MKTypography>
                                    <Grid container>
                                        <Grid
                                            item
                                            xs={12}
                                            className="ck-spot-edit-input-container mt-2"
                                        >
                                            <CKNumberSelector
                                                value={props.spot.$amount}
                                                onChange={(e) => {
                                                    props.spot.$amount = e
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6} className="text-center">
                                    <MKTypography
                                        variant="caption"
                                        fontWeight="bold"
                                        className="text-center w-100"
                                    >
                                        Lengte (min.)
                                    </MKTypography>
                                    <Grid
                                        item
                                        xs={12}
                                        className="ck-spot-edit-input-container mt-2"
                                    >
                                        <CKNumberSelector
                                            value={props.spot.$length}
                                            onChange={(e) => {
                                                props.spot.$length = e
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12} className="text-center">
                            {props.onDelete && (
                                <MKButton
                                    variant={'outlined'}
                                    color="error"
                                    size="small"
                                    className="m-1"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        props.onDelete(props.spot)
                                    }}
                                >
                                    <DeleteIcon className="me-2" /> Verwijder spot
                                </MKButton>
                            )}
                        </Grid>
                    </>
                )}
            </Grid>
        </Card>
    )
}
export default CKSpotEditCard
